/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var download = function () {
    var appdownload = null;

    /**
     * Para tratamento de eventos...
     * */
    var eventHandler = function () {
        try 
        {
            $(".folder p",appdownload).click(function (){
                var ajuda =$(this).parent();
               if($(ajuda).hasClass("active")){
                   $(ajuda).removeClass("active");
               }else
               {
                   $(ajuda).addClass("active");
               }   
            });
        } catch (e) {
            console.log('[ERRO:DOWNLOADS]: ' + e);
        }
    };
    return {
        init: function (_appdownload) {
            appdownload = _appdownload;
            eventHandler();
        }
    };
}();
download.init();



